var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { hidePreloader, showPreloader } from "./preloader";
import { addGlobalLiveEventListener, fixInjectedInlineScripts, onTransitionEnd } from '../utils';
import ajax from "./ajax";
import $ from '../vendor/dom7';
import { StatusClassNames } from "../types/StatusClassNames";
import EventBus from "../events/EventBus";
const unrouted = ['active_user', 'review'];
let modalStack = new Set();
export default function initModal() {
    document.addEventListener('DOMContentLoaded', () => {
        // ссылки, вызывающие модальные окна
        addGlobalLiveEventListener('click', '.win', function (e) {
            const button = this;
            if (button.dataset.modalId) {
                const dialog = document.getElementById(`modal-${button.dataset.modalId}`);
                if (dialog) {
                    showDialogModal(dialog);
                    return;
                }
            }
            const act = button.dataset.act;
            e.preventDefault();
            showPreloader();
            ajax(`/ajax/win/${act}`, Object.assign(Object.assign({}, (button.dataset.id ? { id: button.dataset.id } : {})), { type: button.dataset.type }))
                .then(response => {
                const contentType = response.headers.get("content-type");
                if (contentType && contentType.indexOf("/json") !== -1) {
                    return response.json();
                }
                return response.text();
            })
                .then(function (data) {
                if (typeof data === 'string') {
                    showModal(data);
                }
                else if (data.redirect) {
                    document.location.href = data.redirect;
                    // @ts-ignore
                    document.location.reload(true);
                }
                else {
                    const { assets, callback, html } = data, appData = __rest(data, ["assets", "callback", "html"]);
                    initAssets(data)
                        .then(element => {
                        if (html) {
                            element = showModal(element !== null && element !== void 0 ? element : html);
                        }
                        if (callback) {
                            if (typeof global[callback] === 'function') {
                                return global[callback](appData, element);
                            }
                        }
                    });
                }
            });
            function showModal(html) {
                const id = showModalFromHtml(html);
                if (id) {
                    button.dataset.modalId = id;
                }
                hidePreloader();
                return document.getElementById(id);
            }
        });
        // отправка форм аяксом в модальных окнах
        addGlobalLiveEventListener('submit', 'form', function (e) {
            var _a, _b;
            const form = this;
            const act = (_b = (_a = (e.submitter ? e.submitter : form.querySelector('button[type="submit"]'))) === null || _a === void 0 ? void 0 : _a.dataset) === null || _b === void 0 ? void 0 : _b.act;
            const url = `/ajax/win_act/${act}${unrouted.includes(act) ? '.php' : ''}`;
            if (!act || !e.submitter.classList.contains('ajax_submit')) {
                return;
            }
            e.preventDefault();
            showPreloader();
            let closePreloader = true;
            ajax(url, form, { submitter: e.submitter })
                .then(response => response.json())
                .then((json) => {
                if (json && json.ok) {
                    EventBus.emit('modal:form-success', { action: act, form, response: json });
                    if (json.html) {
                        showModalFromHtml(json.html);
                    }
                    else if (json.win) {
                        const existingModal = document.getElementById(json.win);
                        if (existingModal) {
                            showDialogModal(existingModal);
                        }
                        else {
                            $('<a class="win" data-act="' + json.win + '"></a>').appendTo('.window').click().remove();
                        }
                    }
                    else {
                        const modal = form.closest('.modal');
                        const redirect = modal === null || modal === void 0 ? void 0 : modal.dataset.redirect;
                        closePreloader = false;
                        if (modal && (modal === null || modal === void 0 ? void 0 : modal.dataset.noRedirect) !== undefined) {
                            return;
                        }
                        if (!redirect) {
                            //@ts-ignore
                            document.location.reload(true);
                        }
                        else {
                            document.location.href = redirect;
                            // @ts-ignore
                            document.location.reload(true);
                        }
                        return;
                    }
                }
                else if (json && typeof json.error === 'object') {
                    highlightFormErrors(form, json.error);
                    EventBus.emit('modal:form-error', { action: act, errors: json.error, form });
                }
            })
                .finally(() => closePreloader && hidePreloader());
        });
        // ajax submittable forms
        $(document.body).on('click', '.ajax_submit', function () {
            const $form = $(this).parents('form');
            if (this.type !== 'submit') {
                let $submit = $form.find('[type=submit]');
                if (!$submit.length) {
                    const $input = $('<input type="submit">');
                    $input.hide();
                    $submit = $input.appendTo($form);
                }
                $submit.click();
            }
        });
        $('.win[data-open="1"]').click();
        const autoOpenModal = document.querySelector('dialog.modal[data-auto-open]');
        if (autoOpenModal) {
            showDialogModal(autoOpenModal);
        }
        // handle hash navigation
        checkHashDeeplink(document.location.hash.replace(/^#/, ''));
        EventBus.on('modal:close', modal => {
            if (modal.dataset.redirect) {
                showPreloader();
                document.location.href = modal.dataset.redirect;
                // @ts-ignore
                document.location.reload(true);
            }
        });
    });
}
export function highlightFormErrors(form, errors = {}) {
    resetFormErrors(form);
    for (const field in errors) {
        const element = form.querySelector(`input[name="${field}"],textarea[name="${field}"],select[name="${field}"]`);
        let group = element.closest('.group, .form-group');
        const caption = group.querySelector('.form-caption--error');
        if (caption) {
            group.classList.add('has-error');
            group.querySelector('.form-caption--error').innerText = errors[field];
        }
    }
}
export function resetFormErrors(form) {
    form.querySelectorAll('.has-error').forEach(el => el.classList.remove('has-error'));
}
export function closeExistingModal(destroy = false, exclude = null) {
    for (const openModal of document.querySelectorAll('.window dialog.modal[open]')) {
        if (exclude === openModal) {
            continue;
        }
        closeDialogModal(openModal, destroy);
    }
}
export function showModalFromHtml(modal) {
    const modalRoot = document.querySelector('.window');
    if (!modalRoot) {
        return;
    }
    if (typeof modal === 'string') {
        const wrapper = document.createElement('div');
        wrapper.innerHTML = modal;
        modal = wrapper.firstElementChild;
    }
    const existingSameModal = document.getElementById(modal.id);
    if (existingSameModal) {
        closeDialogModal(existingSameModal);
    }
    modalRoot.append(modal);
    fixInjectedInlineScripts(modalRoot);
    showDialogModal(modal);
    return modal.id;
}
export function getModalAppRootElement() {
    const modalsWrap = document.querySelector('.window');
    const appRoot = document.createElement('div');
    for (const openModal of document.querySelectorAll('.window dialog.modal:not([open])')) {
        destroyModal(openModal);
    }
    closeExistingModal(true);
    modalsWrap.append(appRoot);
    return appRoot;
}
export function getTopmostModal() {
    return [...modalStack].pop();
}
export function closeDialogModal(modal, destroy = false) {
    modalStack.delete(modal);
    modal.classList.remove(StatusClassNames.open);
    modal.classList.add(StatusClassNames.closing);
    modal.querySelectorAll('button[data-close]').forEach(el => el.removeEventListener('click', onCloseButtonClick));
    EventBus.emit('modal:close', modal);
    onTransitionEnd(modal, () => {
        modal.close();
        modal.ariaHidden = 'true';
        modal.classList.add('hidden');
        modal.classList.remove(StatusClassNames.closing);
        if (!document.querySelector('dialog.modal[open]')) {
            document.body.classList.remove(StatusClassNames.noscroll);
        }
        if (destroy) {
            destroyModal(modal);
        }
        else {
            const form = modal.querySelector('form');
            if (form) {
                resetFormErrors(form);
            }
        }
    }, 'opacity');
}
export function showDialogModal(modal) {
    modalStack.add(modal);
    if (modal.dataset.final !== undefined) {
        closeExistingModal(true, modal);
    }
    EventBus.emit('modal:open', modal);
    document.body.classList.add(StatusClassNames.noscroll);
    modal.ariaHidden = 'false';
    modal.showModal();
    handleEscapeCloseTransition(modal);
    closeModalOnBackdropClick(modal);
    modal.classList.remove('hidden');
    modal.querySelectorAll('button[data-close]').forEach(el => el.addEventListener('click', onCloseButtonClick));
    setTimeout(() => {
        modal.classList.add(StatusClassNames.open);
        hidePreloader();
    }, 20);
}
function onCloseButtonClick(event) {
    const modal = event.target.closest('dialog.modal[open]');
    if (modal) {
        closeDialogModal(modal);
    }
}
function destroyModal(modal) {
    const app = modal.closest('[data-v-app]');
    if (app && app['__vue_app__']) {
        app['__vue_app__'].unmount();
        app.remove();
    }
    else {
        modal.remove();
    }
}
function closeModalOnBackdropClick(modal) {
    let backdropClick = false;
    const onpointerdown = (event) => backdropClick = event.target === modal;
    const onpointerup = (event) => {
        if (!backdropClick || event.target !== modal) {
            backdropClick = false;
            return;
        }
        modal.removeEventListener('pointerdown', onpointerdown);
        modal.removeEventListener('pointerup', onpointerup);
        closeDialogModal(modal);
    };
    modal.addEventListener('pointerdown', onpointerdown);
    modal.addEventListener('pointerup', onpointerup);
}
let loading = 0;
function initAssets(data) {
    if (!data.assets) {
        return new Promise(resolve => resolve(null));
    }
    const existingAssets = [
        ...Array.from(document.scripts).map(el => el.src),
        ...Array.from(document.querySelectorAll('link[rel="stylesheet"]')).map(el => el.href),
    ];
    const virtual = document.createElement('div');
    virtual.innerHTML = data.html + data.assets;
    return new Promise(resolve => {
        const resolveCallback = () => {
            resolve(virtual.firstElementChild);
        };
        for (const element of virtual.querySelectorAll('script[src]')) {
            if (!existingAssets.includes(element.src)) {
                enqueueAsset(element, resolveCallback);
            }
        }
        for (const element of virtual.querySelectorAll('link')) {
            if (!existingAssets.includes(element.href)) {
                enqueueAsset(element, resolveCallback);
            }
        }
        if (data.html) {
            for (const element of virtual.querySelectorAll('script,style')) {
                virtual.firstElementChild.append(element);
            }
        }
        if (!loading) {
            resolveCallback();
        }
    });
}
function enqueueAsset(element, resolve) {
    loading++;
    let el = document.createElement(element.tagName);
    document.body.append(el);
    el.onload = () => !--loading && resolve();
    if ('src' in el && 'src' in element) {
        if (element.type) {
            el.type = element.type;
        }
        else if ('noModule' in el) {
            el.noModule = element.attributes['nomodule'] !== undefined;
            loading--;
        }
        el.src = element.src;
    }
    else if ('href' in el && 'href' in element) {
        el.rel = element.rel;
        el.href = element.href;
    }
    element.remove();
}
function handleEscapeCloseTransition(modal) {
    modal.addEventListener('keydown', (e) => {
        if (e.code === 'Escape') {
            // Этот код нужен т.к. иначе браузер закроет диалог мгновенно и события keyup не произойдёт
            e.preventDefault();
        }
    });
    modal.addEventListener('keyup', (e) => {
        if (e.code === 'Escape') {
            e.preventDefault();
            e.stopPropagation();
            closeDialogModal(modal);
        }
    });
}
function checkHashDeeplink(hash) {
    if (!hash) {
        return;
    }
    const modal = document.querySelector(`.win[data-act="${hash}"]`);
    if (!modal) {
        return;
    }
    modal.click();
}
