import { $, addClass, append, appendTo, attr, children, click, closest, css, data, each, filter, find, hasClass, hide, html, is, next, nextAll, off, on, parent, parents, prev, prevAll, remove, removeAttr, removeClass, show, siblings, text, toggleClass, trigger, } from 'dom7';
const Methods = {
    addClass,
    append,
    appendTo,
    attr,
    children,
    click,
    closest,
    css,
    data,
    each,
    filter,
    find,
    hasClass,
    hide,
    html,
    is,
    next,
    nextAll,
    off,
    on,
    parent,
    parents,
    prev,
    prevAll,
    remove,
    removeAttr,
    removeClass,
    show,
    siblings,
    text,
    toggleClass,
    trigger,
};
Object.keys(Methods).forEach(methodName => {
    Object.defineProperty($.fn, methodName, {
        value: Methods[methodName],
        writable: true
    });
});
export default $;
